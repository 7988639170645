<template>
  <div>
    <Content :style="{ padding: '0 24px 16px' }">
      <div class="main-body" id="printMe">
        <div class="select-two main-search">
          <div class="st-block">
            <div class="st-label">车间名称</div>
            <div class="st-select">
              <Select v-model="deviceType" @on-change="resetGetInfo">
                <Option value="-1">全部</Option>
                <Option
                  v-for="(item, index) in workshops"
                  :value="item.workshopId"
                  :key="index"
                  >{{ item.workshop }}</Option
                >
              </Select>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click.stop="() => adddev()">
            <i class="iconfont iconicon-zengjia"></i>
            &nbsp;&nbsp;新建设备组
          </div>
        </div>
        <div class="table-area" :class="isMin == true ? 'minTableArea' : ''">
          <Table
            no-data-text=" "
            max-height="750"
            class="devTable"
            border
            ref="selection"
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
            :class="{ minTable: isMin }"
          ></Table>
        </div>
        <div class="main-page" style="margin-top:21px">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
      <Modal
        v-model="modalFlagAppend"
        title="新建设备组"
        width="440"
        :mask-closable="false"
      >
        <Form
          ref="departForm"
          class="newgroup"
          :model="departForm"
          :rules="formRules"
          :label-width="60"
        >
          <FormItem label="车间" prop="deviceType" class="work">
            <div class="st-select">
              <Select v-model="departForm.deviceType">
                <Option
                  v-for="(item, index) in workshops"
                  :value="item.workshopId"
                  :key="index"
                  >{{ item.workshop }}</Option
                >
              </Select>
            </div>
          </FormItem>
          <FormItem label="组名" prop="name" class="groupname">
            <Input
              ref="focus"
              v-model.trim="departForm.name"
              placeholder="10个文字以内"
              :maxlength="10"
              class="input"
            />
          </FormItem>
          <Form-item label="备注" class="mark">
            <i-input
              v-model.trim="departForm.mark"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 5 }"
            ></i-input>
          </Form-item>
        </Form>
        <div slot="footer">
          <Button @click="handleReset" class="white-btn">取 消</Button>
          <Button type="primary" @click="addMachineGroup('departForm')"
            >确 定</Button
          >
        </div>
      </Modal>
    </Content>
    <Modal v-model="delModal" title="删除" width="440">
      <div style="margin-bottom:17px">
        <i class="iconfont iconicon-gantanhao modalGantanhao"></i
        >是否确定删除该设备组？
      </div>
      <div class="modalDelMessage">删除后，该组的设备可以重新被分组。</div>
      <div slot="footer">
        <Button @click="delModal = false" class="white-btn">取消</Button>
        <Button type="primary" @click="realDelete">确定</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      groupID: "",
      delModal: false,
      isMin: false,
      creenWidth: "", // 屏幕宽度
      pageSize: 10,
      pageNum: 1,
      total: 0,
      pages: 0,
      deviceType: "-1",
      modalFlagAppend: false,
      workshops: [],
      departForm: {
        deviceType: "",
        name: "",
        mark: ""
      },
      formRules: {
        name: [
          { required: true, trigger: "blur", message: "此项必填！" },
          { type: "string", max: 10, message: "名称不能超过 10 个字符" }
        ],
        deviceType: [
          {
            required: true,
            type: "number",
            trigger: "change",
            message: "此项必填！"
          }
        ]
      },
      theadDate: [
        {
          title: "组名",
          align: "center",
          key: "groupName",
          minWidth: 120
        },
        {
          title: "所属车间",
          align: "center",
          key: "workshopName",
          minWidth: 120
        },
        {
          title: "设备数量",
          align: "center",
          key: "deviceNumber",
          minWidth: 120
        },
        {
          title: "备注",
          align: "center",
          key: "remark",
          minWidth: 150
        },
        {
          title: "操作",
          key: "action",
          width: "",
          align: "center",
          minWidth: 120,
          render: (h, params) => {
            const arr = [];
            arr.push(
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  style: {
                    boxShadow: "none",
                    color: "rgba(87,163,243,1)"
                  },
                  on: {
                    click: () => {
                      this.$router.push({
                        path: "/deviceManage/machineallocation",
                        // query: { groupId: params.row.id,groupName:params.row.groupName }
                        query: { row: params.row }
                      });
                    }
                  }
                },
                "查看"
              )
            );
            arr.push(
              h(
                "Button",
                {
                  props: {
                    type: "text",
                    size: "small"
                  },
                  style: {
                    boxShadow: "none",
                    color: "rgba(87,163,243,1)"
                  },
                  on: {
                    click: () => {
                      this.delMachineGroup(params.row.id);
                    }
                  }
                },
                "删除"
              )
            );
            return h("div", arr);
          }
        }
      ],
      tableDate: [],
      loading: false
    };
  },
  created() {
    this.getWorkshopList();
    this.getdeviceGroupList();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        // console.log(this.screenWidth);
        if (this.screenWidth > 1600) {
          this.isMin = true;
        } else {
          this.isMin = false;
        }
      })();
    };
  },
  methods: {
    // 修改每页条数
    pagesizechange(pagesize) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.getdeviceGroupList();
    },
    // 修改页码
    pagenumberchange(pagenum) {
      this.pageNum = pagenum;
      this.getdeviceGroupList();
    },
    resetGetInfo() {
      this.pageSize = 10;
      this.pageNum = 1;
      // this.searchList();
      this.getdeviceGroupList();
    },
    adddev() {
      this.modalFlagAppend = true;
      this.departForm.name = "";
      this.departForm.mark = "";
      this.departForm.deviceType = "";
    },
    // 点击取消 关闭新增弹框
    handleReset() {
      this.modalFlagAppend = false;
    },
    getWorkshopList() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/workshoplist",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.workshops = res.data.body.workshoplist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getdeviceGroupList() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/deviceGroupList",
        method: "get",
        params: {
          pageNum: that.pageNum,
          pageSize: that.pageSize,
          workShopId: this.deviceType // 车间ID
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate = res.data.body.deviceGroupList.list;
            that.total = res.data.body.deviceGroupList.total;
            that.pages = res.data.body.deviceGroupList.pages;
            that.pageSize = res.data.body.deviceGroupList.pageSize;
            that.pageNum = res.data.body.deviceGroupList.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 新增设备组
    addMachineGroup(name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          const params = {
            workShopId: this.departForm.deviceType,
            deviceGroupName: this.departForm.name,
            remmark: this.departForm.mark
          };
          this.axios({
            url: "/dtsum/zongtong/device/DeviceController/addDeviceGroup",
            method: "get",
            params: params
          })
            .then((res) => {
              if (res.data.success === 1) {
                this.$Message.success("新增成功");
                this.handleReset();
                this.getdeviceGroupList();
              } else {
                this.$Message.error(res.msg);
                this.departForm.name = "";
                this.departForm.mark = "";
                this.$refs.focus.focus();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
    // 删除设备组
    delMachineGroup(groupID) {
      this.delModal = true;
      this.groupID = groupID;
    },
    realDelete() {
      const params = {
        groupId: this.groupID
      };
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/deviceGroupDelete",
        method: "get",
        params: params
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("删除成功！");
            this.delModal = false;
            this.getdeviceGroupList();
          } else {
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-two {
    // margin-top: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .st-block {
      height: 36px;
      line-height: 36px;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: 36px;
      }
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  .table-area {
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        // position: fixed !important;
        tbody .ivu-table-row {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
          }
        }
        .ivu-table-wrapper /deep/ .ivu-table {
          .ivu-table-body {
            // position: fixed !important;
            tbody .ivu-table-row {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
              }
              .blueFont /deep/ {
                color: rgba(87, 163, 243, 1);
                .ivu-table-cell .ivu-btn {
                  span {
                    border-bottom: 1px solid rgba(87, 163, 243, 1);
                    height: 18px;
                  }
                }
              }
            }
            tbody .ivu-table-row:hover {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
              td .ivu-table-cell .ivu-btn {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.ivu-select-selection .ivu-select-placeholder {
  height: 24px;
  line-height: 24px;
}
.main-page {
  margin-top: 15px;
}
.modalGantanhao {
  color: #ff1414;
  margin-right: 12px;
}
.ivu-input {
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#fff),
    to(#fff)
  );
}
</style>
